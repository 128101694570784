import { Control_Panel_Sizing } from "../generated/graphql";

const sizing40x24x10w150 = {height : 40, width : 24, depth : 10, weight : 150};
const sizing40x32x12w175 = {height : 40, width : 32, depth : 12, weight : 175};
const sizing48x32x12w200 = {height : 48, width : 32, depth : 12, weight : 200};
const sizing60x36x12w350 = {height : 60, width : 36, depth : 12, weight : 350};
const customPlusExtraCostFactor = 100.00;
const customPlusExtraWeightFactor = 20;
const customExtraCostFactor = 100;
const customExtraWeightFactor = 10;
const customPlusOutdoorCost = 400.00;

// First index is number of fans, second is Amperage
const ecPlusPrices = {
    1 : {
        cost : 3449.69,
        15 : sizing40x24x10w150,
        20 : sizing40x24x10w150
    },
    2 : {
        cost : 3614.65,
        15 : sizing40x24x10w150,
        20 : sizing40x24x10w150,
        25 : sizing40x24x10w150,
        30 : sizing40x24x10w150,
        35 : sizing40x24x10w150
    },
    3 : {
        cost : 3676.79,
        15 : sizing40x24x10w150,
        20 : sizing40x24x10w150,
        25 : sizing40x24x10w150,
        30 : sizing40x24x10w150,
        35 : sizing40x24x10w150,
        40 : sizing40x24x10w150,
        45 : sizing40x24x10w150
    },
    4 : {
        cost : 3723.71,
        15 : sizing40x24x10w150,
        20 : sizing40x24x10w150,
        25 : sizing40x24x10w150,
        30 : sizing40x24x10w150,
        35 : sizing40x24x10w150,
        40 : sizing40x24x10w150,
        45 : sizing40x24x10w150,
        50 : sizing40x24x10w150
    },
    5 : {
        cost : 3774.12,
        15 : sizing40x24x10w150,
        20 : sizing40x24x10w150,
        25 : sizing40x24x10w150,
        30 : sizing40x24x10w150,
        35 : sizing40x24x10w150,
        40 : sizing40x24x10w150,
        45 : sizing40x24x10w150,
        50 : sizing40x24x10w150,
        60 : sizing40x24x10w150
    },
    6 : {
        cost : 4052.93,
        15 : sizing40x32x12w175,
        20 : sizing40x32x12w175,
        25 : sizing40x32x12w175,
        30 : sizing40x32x12w175,
        35 : sizing40x32x12w175,
        40 : sizing40x32x12w175,
        45 : sizing40x32x12w175,
        50 : sizing40x32x12w175,
        60 : sizing40x32x12w175,
        70 : sizing40x32x12w175
    },
    7 : {
        cost : 4142.04,
        15 : sizing40x32x12w175,
        20 : sizing40x32x12w175,
        25 : sizing40x32x12w175,
        30 : sizing40x32x12w175,
        35 : sizing40x32x12w175,
        40 : sizing40x32x12w175,
        45 : sizing40x32x12w175,
        50 : sizing40x32x12w175,
        60 : sizing40x32x12w175,
        70 : sizing40x32x12w175,
        80 : sizing40x32x12w175
    },
    8 : {
        cost : 4211.13,
        15 : sizing40x32x12w175,
        20 : sizing40x32x12w175,
        25 : sizing40x32x12w175,
        30 : sizing40x32x12w175,
        35 : sizing40x32x12w175,
        40 : sizing40x32x12w175,
        45 : sizing40x32x12w175,
        50 : sizing40x32x12w175,
        60 : sizing40x32x12w175,
        70 : sizing40x32x12w175,
        80 : sizing40x32x12w175,
        90 : sizing40x32x12w175,
        100 : sizing40x32x12w175
    },
    9 : {
        cost : 4485.7,
        15 : sizing40x32x12w175,
        20 : sizing40x32x12w175,
        25 : sizing40x32x12w175,
        30 : sizing40x32x12w175,
        35 : sizing40x32x12w175,
        40 : sizing40x32x12w175,
        45 : sizing40x32x12w175,
        50 : sizing40x32x12w175,
        60 : sizing40x32x12w175,
        70 : sizing40x32x12w175,
        80 : sizing40x32x12w175,
        90 : sizing40x32x12w175,
        100 : sizing40x32x12w175,
        110 : sizing40x32x12w175
    },
    10 : {
        cost : 4544.98,
        15 : sizing40x32x12w175,
        20 : sizing40x32x12w175,
        25 : sizing40x32x12w175,
        30 : sizing40x32x12w175,
        35 : sizing40x32x12w175,
        40 : sizing40x32x12w175,
        45 : sizing40x32x12w175,
        50 : sizing40x32x12w175,
        60 : sizing40x32x12w175,
        70 : sizing40x32x12w175,
        80 : sizing40x32x12w175,
        90 : sizing40x32x12w175,
        100 : sizing40x32x12w175,
        110 : sizing40x32x12w175,
        125 : sizing40x32x12w175
    },
    11 : {
        cost : 4709.66,
        15 : sizing40x32x12w175,
        20 : sizing40x32x12w175,
        25 : sizing40x32x12w175,
        30 : sizing40x32x12w175,
        35 : sizing40x32x12w175,
        40 : sizing40x32x12w175,
        45 : sizing40x32x12w175,
        50 : sizing40x32x12w175,
        60 : sizing40x32x12w175,
        70 : sizing40x32x12w175,
        80 : sizing40x32x12w175,
        90 : sizing40x32x12w175,
        100 : sizing48x32x12w200,
        110 : sizing48x32x12w200,
        125 : sizing48x32x12w200
    },
    12 : {
        cost : 4790.52,
        15 : sizing40x32x12w175,
        20 : sizing40x32x12w175,
        25 : sizing40x32x12w175,
        30 : sizing40x32x12w175,
        35 : sizing40x32x12w175,
        40 : sizing40x32x12w175,
        45 : sizing40x32x12w175,
        50 : sizing40x32x12w175,
        60 : sizing40x32x12w175,
        70 : sizing40x32x12w175,
        80 : sizing40x32x12w175,
        90 : sizing40x32x12w175,
        100 : sizing48x32x12w200,
        110 : sizing48x32x12w200,
        125 : sizing48x32x12w200
    },
    13 : {
        cost : 4855.64,
        15 : sizing40x32x12w175,
        20 : sizing40x32x12w175,
        25 : sizing40x32x12w175,
        30 : sizing40x32x12w175,
        35 : sizing40x32x12w175,
        40 : sizing40x32x12w175,
        45 : sizing40x32x12w175,
        50 : sizing40x32x12w175,
        60 : sizing40x32x12w175,
        70 : sizing40x32x12w175,
        80 : sizing40x32x12w175,
        90 : sizing40x32x12w175,
        100 : sizing48x32x12w200,
        110 : sizing48x32x12w200,
        125 : sizing48x32x12w200
    },
    14 : {
        cost : 4903.33,
        15 : sizing40x32x12w175,
        20 : sizing40x32x12w175,
        25 : sizing40x32x12w175,
        30 : sizing40x32x12w175,
        35 : sizing40x32x12w175,
        40 : sizing40x32x12w175,
        45 : sizing40x32x12w175,
        50 : sizing40x32x12w175,
        60 : sizing40x32x12w175,
        70 : sizing40x32x12w175,
        80 : sizing40x32x12w175,
        90 : sizing40x32x12w175,
        100 : sizing48x32x12w200,
        110 : sizing48x32x12w200,
        125 : sizing48x32x12w200
    },
    15 : {
        cost : 4956.13,
        15 : sizing48x32x12w200,
        20 : sizing48x32x12w200,
        25 : sizing48x32x12w200,
        30 : sizing48x32x12w200,
        35 : sizing48x32x12w200,
        40 : sizing48x32x12w200,
        45 : sizing48x32x12w200,
        50 : sizing48x32x12w200,
        60 : sizing48x32x12w200,
        70 : sizing48x32x12w200,
        80 : sizing48x32x12w200,
        90 : sizing48x32x12w200,
        100 : sizing48x32x12w200,
        110 : sizing48x32x12w200,
        125 : sizing48x32x12w200
    },
    16 : {
        cost : 5127.28,
        15 : sizing48x32x12w200,
        20 : sizing48x32x12w200,
        25 : sizing48x32x12w200,
        30 : sizing48x32x12w200,
        35 : sizing48x32x12w200,
        40 : sizing48x32x12w200,
        45 : sizing48x32x12w200,
        50 : sizing48x32x12w200,
        60 : sizing48x32x12w200,
        70 : sizing48x32x12w200,
        80 : sizing48x32x12w200,
        90 : sizing48x32x12w200,
        100 : sizing60x36x12w350,
        110 : sizing60x36x12w350,
        125 : sizing60x36x12w350
    },
    17 : {
        cost : 5208.2,
        15 : sizing48x32x12w200,
        20 : sizing48x32x12w200,
        25 : sizing48x32x12w200,
        30 : sizing48x32x12w200,
        35 : sizing48x32x12w200,
        40 : sizing48x32x12w200,
        45 : sizing48x32x12w200,
        50 : sizing48x32x12w200,
        60 : sizing48x32x12w200,
        70 : sizing48x32x12w200,
        80 : sizing48x32x12w200,
        90 : sizing48x32x12w200,
        100 : sizing60x36x12w350,
        110 : sizing60x36x12w350,
        125 : sizing60x36x12w350
    },
    18 : {
        cost : 5271.83,
        15 : sizing48x32x12w200,
        20 : sizing48x32x12w200,
        25 : sizing48x32x12w200,
        30 : sizing48x32x12w200,
        35 : sizing48x32x12w200,
        40 : sizing48x32x12w200,
        45 : sizing48x32x12w200,
        50 : sizing48x32x12w200,
        60 : sizing48x32x12w200,
        70 : sizing48x32x12w200,
        80 : sizing48x32x12w200,
        90 : sizing48x32x12w200,
        100 : sizing60x36x12w350,
        110 : sizing60x36x12w350,
        125 : sizing60x36x12w350
    },
    19 : {
        cost : 7297.55,
        15 : sizing48x32x12w200,
        20 : sizing48x32x12w200,
        25 : sizing48x32x12w200,
        30 : sizing48x32x12w200,
        35 : sizing48x32x12w200,
        40 : sizing48x32x12w200,
        45 : sizing48x32x12w200,
        50 : sizing48x32x12w200,
        60 : sizing48x32x12w200,
        70 : sizing48x32x12w200,
        80 : sizing48x32x12w200,
        90 : sizing48x32x12w200,
        100 : sizing60x36x12w350,
        110 : sizing60x36x12w350,
        125 : sizing60x36x12w350
    },
    20 : {
        cost : 7350.36,
        15 : sizing48x32x12w200,
        20 : sizing48x32x12w200,
        25 : sizing48x32x12w200,
        30 : sizing48x32x12w200,
        35 : sizing48x32x12w200,
        40 : sizing48x32x12w200,
        45 : sizing48x32x12w200,
        50 : sizing48x32x12w200,
        60 : sizing48x32x12w200,
        70 : sizing48x32x12w200,
        80 : sizing48x32x12w200,
        90 : sizing48x32x12w200,
        100 : sizing60x36x12w350,
        110 : sizing60x36x12w350,
        125 : sizing60x36x12w350
    },
    21 : {
        cost : 7450.36,
        15 : sizing60x36x12w350,
        20 : sizing60x36x12w350,
        25 : sizing60x36x12w350,
        30 : sizing60x36x12w350,
        35 : sizing60x36x12w350,
        40 : sizing60x36x12w350,
        45 : sizing60x36x12w350,
        50 : sizing60x36x12w350,
        60 : sizing60x36x12w350,
        70 : sizing60x36x12w350,
        80 : sizing60x36x12w350,
        90 : sizing60x36x12w350,
        100 : sizing60x36x12w350,
        110 : sizing60x36x12w350,
        125 : sizing60x36x12w350
    },
    22 : {
        cost : 7550.36,
        15 : sizing60x36x12w350,
        20 : sizing60x36x12w350,
        25 : sizing60x36x12w350,
        30 : sizing60x36x12w350,
        35 : sizing60x36x12w350,
        40 : sizing60x36x12w350,
        45 : sizing60x36x12w350,
        50 : sizing60x36x12w350,
        60 : sizing60x36x12w350,
        70 : sizing60x36x12w350,
        80 : sizing60x36x12w350,
        90 : sizing60x36x12w350,
        100 : sizing60x36x12w350,
        110 : sizing60x36x12w350,
        125 : sizing60x36x12w350
    },
    23 : {
        cost : 7650.36,
        15 : sizing60x36x12w350,
        20 : sizing60x36x12w350,
        25 : sizing60x36x12w350,
        30 : sizing60x36x12w350,
        35 : sizing60x36x12w350,
        40 : sizing60x36x12w350,
        45 : sizing60x36x12w350,
        50 : sizing60x36x12w350,
        60 : sizing60x36x12w350,
        70 : sizing60x36x12w350,
        80 : sizing60x36x12w350,
        90 : sizing60x36x12w350,
        100 : sizing60x36x12w350,
        110 : sizing60x36x12w350,
        125 : sizing60x36x12w350
    },
    24 : {
        cost : 7750.36,
        15 : sizing60x36x12w350,
        20 : sizing60x36x12w350,
        25 : sizing60x36x12w350,
        30 : sizing60x36x12w350,
        35 : sizing60x36x12w350,
        40 : sizing60x36x12w350,
        45 : sizing60x36x12w350,
        50 : sizing60x36x12w350,
        60 : sizing60x36x12w350,
        70 : sizing60x36x12w350,
        80 : sizing60x36x12w350,
        90 : sizing60x36x12w350,
        100 : sizing60x36x12w350,
        110 : sizing60x36x12w350,
        125 : sizing60x36x12w350
    },
};

export const getECPlusSizeAndWeight = (fanCount : number, amperage : number) => {
    // If the amount & amperage are there, return the value
    if (ecPlusPrices.hasOwnProperty(fanCount)){
        if(ecPlusPrices[fanCount].hasOwnProperty(amperage)) return ecPlusPrices[fanCount][amperage];
    }
    // Otherwise, it needs to be custom
    // This will probably need to be rewritten when I get a better description of how
    // console.log("calculated EC+ size and weight");
    return {height : "Custom", width : "Custom", depth : "Custom", amperage, weight : fanCount * customPlusExtraWeightFactor}
}

export const getECPlusCost = (fanCount : number, outdoors : boolean | null = false) => {
    // If the amount we have has a price, just return it
    if (ecPlusPrices.hasOwnProperty(fanCount) && ecPlusPrices[fanCount].hasOwnProperty("cost")){
        if (!!outdoors) return ecPlusPrices[fanCount].cost + customPlusOutdoorCost;
        else return ecPlusPrices[fanCount].cost;
    } 
    // Otherwise, we need to get the highest amount of fans w/a price
    // then add the difference multiplied by the extra cost per fan
    let mostFans : number = 0;
    let mostCost : number = 0;
    for (const [fans, data] of Object.entries(ecPlusPrices)){
        if (data.hasOwnProperty("cost") && data["cost"] > mostCost){
            mostCost = data["cost"];
            mostFans = parseInt(fans);
        } 
    }
    // console.log("calculated EC+ cost");
    if (!!outdoors) return ((customPlusExtraCostFactor * (fanCount - mostFans)) + customPlusOutdoorCost + mostCost);
    else return ((customPlusExtraCostFactor * (fanCount - mostFans)) + mostCost);
}

export const getECPlusAll = (fanCount : number, amperage : number, outdoors : boolean | null = false) => {
    return ({sizing : getECPlusSizeAndWeight(fanCount, amperage), cost : getECPlusCost(fanCount, outdoors)});
}

export const getBasicSizeAndWeight = (fanCount : number, amperage: number, sizings : Control_Panel_Sizing[]) => {
    let foundSizing = null;
    // If we have no sizing, we're assuming this is from a non-final reflow and return placeholders
    if (sizings === undefined || (sizings.length === 1 && sizings[0] === undefined)){
        // console.log("returned placeholder basic size and weight");
        return {height : "None", width : "None", depth : "None", weight : 0};
    } else if (sizings.length === 1){
        // If we were only given 1 sizing, assume it's got max amounts or is the correct one
        foundSizing = sizings[0];
    } else {
        // If we find a sizing with the fan count & amperage, just return that
        foundSizing = sizings.find((p) => (p.fan_count === fanCount && p.amperage === amperage));
        // If there are control panels with same number of fans, use that weight
        if (foundSizing === undefined) foundSizing = sizings.find((p) => (p.fan_count === fanCount));
        if (foundSizing !== undefined) return foundSizing;
        // Otherwise we need to get weight for max fans, then add extra weight per fan over that
        let maxWeight : number = 0;
        let maxFans : number = 0;
        sizings.forEach(element => {
            if (element.weight >= maxWeight && element.fan_count >= maxFans){
                maxFans = element.fan_count;
                maxWeight = element.weight
            }
        });
        foundSizing = sizings.find((p) => p.fan_count === maxFans);
    }
    // console.log("calculated basic size and weight");
    return {height : "Custom", width : "Custom", depth : "Custom", amperage, weight : foundSizing.weight + (customExtraWeightFactor * (fanCount - foundSizing.fan_count))};
}

export const getBasicCost = (fanCount : number, amperage: number, sizings: Control_Panel_Sizing[]) => {
    let foundSizing = null;
    // If we have no sizing, we're assuming this is from a non-final reflow and return placeholders
    if (sizings === undefined || (sizings.length === 1 && sizings[0] === undefined)){
        // console.log("returned placeholder basic cost");
        return 0;
    } else if (sizings.length === 1){
        // If we were only given 1 sizing, assume it's got max amounts
        foundSizing = sizings[0];
    } else {
        // If we find a sizing with fan count & amperage, just return that
        foundSizing = sizings.find((p) => (p.fan_count === fanCount && p.amperage === amperage));
        // If there are control panels with same number of fans, use that price
        if (foundSizing === undefined) foundSizing = sizings.find((p) => (p.fan_count === fanCount));
        if (foundSizing !== undefined) return foundSizing.cost;
        // Otherwise we need to get price for max fans, then add extra cost per fan over that
        let maxFans = 0;
        sizings.forEach(element => {if (element.fan_count > maxFans) maxFans = element.fan_count});
        foundSizing = sizings.find((p) => p.fan_count === maxFans);
    }
    // console.log("calculated basic cost");
    return foundSizing.cost + (customExtraCostFactor * (fanCount - foundSizing.fan_count));
}

export const getBasicAll = (fanCount : number, amperage : number, sizings : Control_Panel_Sizing[]) => {
    return ({sizing : getBasicSizeAndWeight(fanCount, amperage, sizings), cost : getBasicCost(fanCount, amperage, sizings)});
}

export const getPanelSizeAndWeight = (fanCount : number, amperage : number | null, sizings: Control_Panel_Sizing[] | null, ECPlus : boolean) => {
    if (ECPlus) return getECPlusSizeAndWeight(fanCount, amperage);
    else return getBasicSizeAndWeight(fanCount, amperage, sizings);
}

export const getPanelCost = (fanCount : number, amperage : number | null, sizings: Control_Panel_Sizing[] | null, ECPlus : boolean, outdoors : boolean | null = false) => {
    if (ECPlus) return getECPlusCost(fanCount, outdoors);
    else return getBasicCost(fanCount, amperage, sizings);
}

export const getPanelAll = (fanCount : number, amperage : number | null, sizings: Control_Panel_Sizing[] | null, ECPlus : boolean, outdoors : boolean | null = false) => {
    if (ECPlus) return getECPlusAll(fanCount, amperage, outdoors);
    else return getBasicAll(fanCount, amperage, sizings);
}

export const isSizingCustom = (fanCount : number, amperage : number, sizings: Control_Panel_Sizing[] | null, ECPlus : boolean) => {
    let cost = true;
    let sizing = true;
    if (!ECPlus){
        if (sizings === undefined || (sizings.length === 1 && sizings[0] === undefined)) {
            // skip for now, since this is not from a final reflow of the page
        } else if (sizings.length === 1){
            if (sizings[0].amperage === amperage && sizings[0].fan_count === fanCount){
                sizing = false;
            } 
            if (sizings[0].fan_count === fanCount) cost = false;
        } else {
            if (sizings.find((p) => (p.fan_count === fanCount && p.amperage === amperage)) !== undefined) sizing = false;
            if (sizings.find((p) => (p.fan_count === fanCount)) !== undefined) cost = false;
        }
    } else {
        if (ecPlusPrices.hasOwnProperty(fanCount) && ecPlusPrices[fanCount].hasOwnProperty(amperage)){
            sizing = false;
            cost = false;
        }
    }
    return {cost, sizing};
}