import React, { ChangeEvent, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Input, Modal, Row } from 'reactstrap';
import { Configuration, Control_Panel_Sizing, Cost, Fan_Fit, Product, Product_Set_Input, useUpdateProductMutation } from '../generated/graphql';
import FanCurveChart from './FanCurveChart';
import { RPMCoefficient } from '../helpers/file-checker';
import { findDesignCoefficients, findMaxCFM } from '../helpers/fan-curve';
import { calculateFanArrayWeight, calculateRedundancy, calculateEfficiency, calculateRPMSoundPower, calculateOperatingInputHorserpower, calculateOperatingRPM, getCostBreakdown, calculateFLA, calculateMCANew, calculateMOCPNew, calculateAltitudeCorrectionFactor } from '../helpers/generate-configuration-query';
import './ExpandedConfigurationDetails.css';
import { generateQuoteDXF } from '../helpers/generate-quote.dxf';
import parseMoney from '../helpers/money-utils';
import { CostBreakdownTooltip } from './CostBreakdownTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPanelAll } from '../helpers/calculate-custom-controls';
import { faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { isEqual } from 'lodash';

const soundPrettyOutput = (value: any) => {
  if (!value || value < 0)
    return "-";
  return value
}

const ExpandedConfigurationDetails = ({
  product,
  configuration,
  fanCount,
  sizing,
  allSizings,
  costs,
  fanFit,
  modal,
  expandedValid,
}: {
  product: Product_Set_Input,
  configuration: Configuration,
  fanCount: number | null,
  fanFit?: Fan_Fit,
  sizing?: Control_Panel_Sizing,
  allSizings?: Control_Panel_Sizing[],
  costs?: Cost[],
  modal?: boolean,
  expandedValid: boolean,
  selectedConfigurationId?: number | null;
  selectedFanCount?: number | null;
}) => {
  // Non-selecting configuration details
  const [additionalConfig, setAdditionalConfig] = useState<Product_Set_Input>({
    pressure_transducers: product.pressure_transducers,
    mas_ec_plus: product.mas_ec_plus,
    outdoor_rating: product.outdoor_rating,
    voltage_override: product.voltage_override,
    large_scale_pricing: product.large_scale_pricing,
    discounted_fan_price: parseMoney(product.discounted_fan_price) ?? null,
  });

  const [savedAdditionalConfig, setSavedAdditionalConfig] = useState<Product_Set_Input>({
    pressure_transducers: product?.pressure_transducers,
    mas_ec_plus: product?.mas_ec_plus,
    outdoor_rating: product?.outdoor_rating,
    voltage_override: product.voltage_override,
    large_scale_pricing: product.large_scale_pricing,
    discounted_fan_price: parseMoney(product.discounted_fan_price) ?? null,
  });

  const additionalConfigUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    let value: any = "";
    if (e.target.name === "outdoor_rating"){
      if (e.target.value === "indoor") value = false;
      if (e.target.value === "outdoor") value = true;
    } else if (e.target.name === "discounted_fan_price") {
      // Lot of special cases needed to use a text input to only input numbers and have a $ at the start...
      if (e.target.value === "") value = null;
      else if (e.target.value === "$") value = null;
      else if (e.target.value.length === 1 && e.target.value[0] !== "$" && !isNaN(parseInt(e.target.value[0]))) value = e.target.value;
      else if (isNaN(parseInt(e.target.value.slice(1)))) value = additionalConfig.discounted_fan_price;
      else value = parseInt(e.target.value.slice(1));
    } else if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else if (e.target.type === "number") {
      try {
        value = parseInt(e.target.value);
        if (Number.isNaN(value)) return;
      } catch (error) {
        console.error("Parse error: ", error);
        return;
      }
    } else {
      value = e.target.value;
    }

    if (e.target.name === "mas_ec_plus" && !value){
      setAdditionalConfig({...additionalConfig, "mas_ec_plus" : false, "outdoor_rating": null });
    } else if (e.target.name === "mas_ec_plus" && !!value && additionalConfig.outdoor_rating === null){
      setAdditionalConfig({...additionalConfig, [e.target.name]: value, "outdoor_rating": true});
    } else if (e.target.name === "outdoor_rating" && !additionalConfig.mas_ec_plus){
      setAdditionalConfig({...additionalConfig, [e.target.name]: value, "mas_ec_plus": true});
    } else if (e.target.name === "large_scale_pricing" && !value) {
      setAdditionalConfig({...additionalConfig, "large_scale_pricing" : false, "discounted_fan_price" : null});
    } else if (e.target.name === "large_scale_pricing" && !!value && savedAdditionalConfig.discounted_fan_price !== null) {
      setAdditionalConfig({...additionalConfig, "large_scale_pricing" : true, "discounted_fan_price" : savedAdditionalConfig.discounted_fan_price});
    } else setAdditionalConfig({...additionalConfig, [e.target.name]: value });
  }

  const clearVoltageOverride = () => {
    setAdditionalConfig({...additionalConfig, "voltage_override" : null});
  }

  const maxVoltage = (configuration?.voltage === 460 ? 480 : 240);
  const minVoltage = (configuration?.voltage === 460 ? 380 : 200);

  const voltageOverrideInvalid = () => {
    if (additionalConfig.voltage_override === null) return false;
    return additionalConfig.voltage_override < minVoltage || additionalConfig.voltage_override > maxVoltage;
  }

  const discountedFanPriceInvalid = () => {
    if (additionalConfig.discounted_fan_price === null) return false;
    return additionalConfig.discounted_fan_price < 0 || additionalConfig.discounted_fan_price > configuration.price;
  }

  const updateConfigPossible = !isEqual(additionalConfig,savedAdditionalConfig) && !voltageOverrideInvalid() && !discountedFanPriceInvalid();

  // update non-selecting product details

  const [updateProduct] = useUpdateProductMutation();

  const updateProductConfig = useCallback(() => {
    if (!updateConfigPossible) return;
    updateProduct({
      variables: {
        pk_columns: { id: product.id },
        _set: {
          ...additionalConfig,
        },
      },
      update: (cache, { data }) => {
        const updated = data?.update_product_by_pk;
        if (!updated) return;
        cache.evict({
          fieldName: "product",
          broadcast: false,
        });

        if (!!updated.quote_id) {
          cache.evict({
            fieldName: "quote",
            broadcast: false,
          });
        }
      },
      onCompleted: () => {
        setSavedAdditionalConfig(additionalConfig);
      }
    });
  }, [additionalConfig, product.id, updateConfigPossible, updateProduct]);

  let buttonHolderClasses = "end-positioned";
  if (updateConfigPossible) buttonHolderClasses += " update-button-collapse-open";
  else buttonHolderClasses += " collapse-closed";
  if (additionalConfig.mas_ec_plus && additionalConfig.large_scale_pricing) buttonHolderClasses += " adjust-button-up"

  // calculation for expanded details

  const cleanFanCount = fanCount ?? 0;
  const fla = calculateFLA(configuration?.fla_460, additionalConfig.voltage_override, configuration?.min_v_fla, configuration?.max_v_fla);

  const rawCoefficients = !!configuration?.rmp_coefficients
    ? JSON.parse(configuration?.rmp_coefficients) : {};

  let coefficients: RPMCoefficient[] = rawCoefficients
        ? Object.values(rawCoefficients)
        : [];

  const altitudeCorrectionFactor = calculateAltitudeCorrectionFactor(product?.altitude);
  const backdraftDampersCorrectionValue = product?.backdraft_dampers ? 0.2 : 0;

  const adjustedCoefficients = coefficients.map(c => Object.assign({}, c, {
      pressure: {
        a: c.pressure.a,
        b: c.pressure.b,
        c: c.pressure.c,
        d: (c.pressure.d/altitudeCorrectionFactor) - backdraftDampersCorrectionValue
      }
    }));

  const cfm = product?.air_flow ?? 0;
  const adjustedTSP = (product?.total_static_pressure ?? 0) * altitudeCorrectionFactor + backdraftDampersCorrectionValue;

  const designCoefficients = findDesignCoefficients({
    cfm,
    tsp: adjustedTSP,
    coefficients,
    fanCount: cleanFanCount,
    model: configuration?.model
  });

  const horsepower = designCoefficients.upper.coefficient?.powerHp && designCoefficients.lower.coefficient?.powerHp ? calculateOperatingInputHorserpower(
    product.air_flow,
    designCoefficients.fanCount || 0,
    designCoefficients.lower.coefficient?.powerHp,
    designCoefficients.upper.coefficient?.powerHp,
    designCoefficients.interpolation
  ) : 0;
  const efficiency = calculateEfficiency({
    tsp: adjustedTSP,
    cfm,
    fanCount: cleanFanCount,
    horsepower,
  });

  const maxRPM = Math.max(...(Object.keys(rawCoefficients).map((k) => parseInt(k))));
  const maxRPMCoefficients = coefficients.sort((a, b) => b.RPM - a.RPM)[0];
  const maxCFM = maxRPMCoefficients
    ? findMaxCFM({
      tsp: adjustedTSP,
      coefficient: maxRPMCoefficients.pressure,
    })
    : 0;

  const redundancy = calculateRedundancy({
    maxCFM,
    fanCount: cleanFanCount,
    designCFM: cfm,
  });

  const mca = calculateMCANew({ fla: fla, fanCount: cleanFanCount, voltage: additionalConfig.voltage_override ?? configuration?.voltage ?? 0, mas_ec_plus: product.mas_ec_plus });
  const mocp = calculateMOCPNew({ fla: fla, fanCount: cleanFanCount, voltage: additionalConfig.voltage_override ?? configuration?.voltage ?? 0, mas_ec_plus: product.mas_ec_plus });

  // Need to recalculate the sizing here in case we're using a custom voltage
  const calculatedSizing = getPanelAll(cleanFanCount, mocp, allSizings, additionalConfig.mas_ec_plus, additionalConfig.outdoor_rating);

  const systemWeight = calculateFanArrayWeight({
    fanCount: cleanFanCount,
    fanWeight: !!configuration?.fan_weight ? configuration.fan_weight : 0 ,
    panelWeight: calculatedSizing.sizing.weight,
    bulkheadHeight: product.height || 0,
    bulkheadWidth: product.width || 0,
  });

  const operatingSpeed = designCoefficients.upper ? calculateOperatingRPM(
    designCoefficients.upper.rpm ?? 0,
    designCoefficients.upper.tsp ?? 0,
    adjustedTSP,
  ) : 0;

  const soundRPM = !!designCoefficients.upper.coefficient && !!designCoefficients.lower.coefficient
    ? calculateRPMSoundPower(cleanFanCount, product.air_flow / cleanFanCount, designCoefficients.lower.coefficient, designCoefficients.upper.coefficient, designCoefficients.interpolation)
    : null;

  const unitCost = getCostBreakdown({
    fanCost: parseMoney(configuration?.price) ?? 0,
    fanCount: cleanFanCount ?? 1,
    controlPanelCost: calculatedSizing.cost,
    bulkheadHeight: product.height ?? 0,
    bulkheadWidth: product.width ?? 0,
    costPerSQFT:
      parseMoney(costs?.find((c) => c.name === "Bulkhead_Cost_per_SQFT")?.value) ?? 0,
    miscFlatCost:
      parseMoney(costs?.find((c) => c.name === "Misc_Flat")?.value) ?? 0,
    miscVariableCost:
      parseMoney(costs?.find((c) => c.name === "Misc_Variable:")?.value) ?? 0,
    hasTransducers: additionalConfig.pressure_transducers ?? false,
    hasDampers: product.backdraft_dampers ?? false,
    damperCost:
      parseMoney(costs?.find((c) => c.name === "Backdraft_Damper")?.value) ?? 250,
    largeScalePricing: additionalConfig.large_scale_pricing,
    discountedFanCost: additionalConfig.discounted_fan_price,
  });

  const container = (
    <div className="config-details-container">
      <div className="details-content">
        <div className='tainer'>
          <div className="details-content-actions">
            <Button
              onClick={() => {
                fanFit && generateQuoteDXF({
                  fileName: `${product.tag}.dxf`,
                  config: configuration,
                  fanFitData: [fanFit],
                  products: [Object.assign({}, product, { configuration }) as Product],
                  sizings: [sizing]
                }).catch((e) => toast.error(e.message))
              }}>Download 2D CAD Drawing</Button>
              <div className="invalid-config-warning" hidden={expandedValid}>
                <FontAwesomeIcon icon="warning" className="icon-sides-padding" />
                Warning: The current saved product settings are not valid for this configuration
                <FontAwesomeIcon icon="warning" className="icon-sides-padding" />
              </div>
          </div>
          <Row className="mb-2">
            <Col lg="6" md="6" sm="6">
              <div className="stats-container">
                <div className="stat-row-container">
                  <div className="state-row-label">
                    <span>Cost
                      <CostBreakdownTooltip unitCost={unitCost}/>
                    </span>
                  </div>
                  <div>
                    <span className={additionalConfig.large_scale_pricing ? "clipped-highlighted" : ""}>${unitCost.totalCost.toLocaleString()}</span>
                  </div>
                </div>
                <StatRow label="Fan Model" value={configuration?.model} symbol='' />
                <StatRow label="Fan Count" value={cleanFanCount} symbol='' />
                <StatRow label="Efficiency" value={(efficiency * 100).toFixed(2)} symbol="%" />
                <StatRow label="Redundancy" value={Math.round(Math.max(0.01, (redundancy * 100)))} symbol="%" />
                <StatRow label="Motor Power" value={configuration?.nominal_hp} symbol="HP" />
                <StatRow label="Input Power" value={horsepower.toFixed(2)} symbol="HP" />
                <StatRow label="Operating Speed" value={operatingSpeed} symbol="RPM" />
                <StatRow label="Maximum Speed" value={maxRPM} symbol="RPM" />
                <StatRow label="System Weight" value={Math.round(systemWeight)} symbol="Lbs" />
                <StatRow label="Fan Weight" value={Math.round(configuration?.fan_weight ?? 0)} symbol="Lbs" />
                <StatRow label="Fan Blade Diameter" value={configuration?.blade_diameter} symbol="MM" />
                <StatRow label="Fan Depth" value={configuration?.depth} symbol="IN" />
                <StatRow label="Control Panel Width" value={calculatedSizing.sizing.width} symbol="IN" />
              </div>
              <h3>Electrical Data</h3>
              <div className="stats-container">
                <StatRow label="FLA" value={fla?.toFixed(2)} symbol="A" />
                <StatRow label="MCA" value={mca?.toFixed(2)} symbol="A" />
                <StatRow label="MOCP" value={Math.round(mocp)} symbol="A" />
              </div>
            </Col>
            <Col lg="6" md="6" sm="6" className="d-flex flex-column justify-content-between align-items-end">
              <div style={{ height: '420px', width: '100%' }}>
                <FanCurveChart
                  tsp={product?.total_static_pressure ?? 0}
                  cfm={cfm}
                  fanCount={cleanFanCount}
                  model={configuration?.model}
                  coefficients={adjustedCoefficients}
                />
              </div>
              <div className="post-config-info-holder">
                <h3>Additional Options</h3>
                <div className="post-config-row-holder">
                  <div className="post-config-column-holder">
                    <div className="post-config-column">
                      <div className='post-config-padded'>
                        <label className="box-label">
                          <Input
                            name="pressure_transducers"
                            type="checkbox"
                            checked={!!additionalConfig.pressure_transducers}
                            onChange={additionalConfigUpdate}
                          /> Pressure Transducers</label>
                      </div>
                      <div className='post-config-padded'>
                        <label className="box-label">
                          <Input
                            name="mas_ec_plus"
                            type="checkbox"
                            checked={!!additionalConfig.mas_ec_plus}
                            onChange={additionalConfigUpdate}
                          /> MAS EC+
                        </label>
                        <div className={additionalConfig.mas_ec_plus ? "uupdate-button-input-open" : "collapse-closed"}>
                          <label className="radio-holder">
                            <input 
                              type="radio" 
                              name="outdoor_rating" 
                              className="radio-button"
                              value="indoor"
                              checked={additionalConfig.outdoor_rating === false} 
                              onChange={additionalConfigUpdate}
                            />
                            Indoor
                          </label>
                          <label>
                            <input 
                              type="radio" 
                              name="outdoor_rating" 
                              className="radio-button"
                              value="outdoor"
                              checked={additionalConfig.outdoor_rating === true} 
                              onChange={additionalConfigUpdate}
                            />
                            Outdoor
                          </label>
                        </div>
                      </div>
                      <div className='post-config-padded'>
                        <label className="box-label">
                          <Input
                            name="large_scale_pricing"
                            type="checkbox"
                            checked={!!additionalConfig.large_scale_pricing}
                            onChange={additionalConfigUpdate}
                          /> High Volume Pricing
                        </label>
                        <div className={additionalConfig.large_scale_pricing ? "update-button-input-open" : "collapse-closed"}>
                          <label className="box-label small-text">Fan Price</label>
                          <Input 
                            type="text"
                            name="discounted_fan_price"
                            placeholder={configuration?.price ?? "Loading fan prices..."}
                            value={additionalConfig.discounted_fan_price !== null ? "$"+additionalConfig.discounted_fan_price : ""}
                            onChange={additionalConfigUpdate}
                            invalid={discountedFanPriceInvalid()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="post-config-column">
                      <div className='post-config-padded'>
                        <label className="box-label">Voltage Override <span className="small-text">({minVoltage}V-{maxVoltage}V)</span></label>
                        <div className="voltage-override-section">
                          <Input 
                            type="number"
                            name="voltage_override"
                            min={minVoltage}
                            max={maxVoltage}
                            value={additionalConfig.voltage_override || ""}
                            onChange={additionalConfigUpdate}
                            invalid={voltageOverrideInvalid()}
                            placeholder={configuration?.voltage}
                          />
                          <button 
                            className="clear-voltage-button"
                            onClick={clearVoltageOverride}
                            disabled={additionalConfig.voltage_override === null}
                          >
                            <FontAwesomeIcon icon={faXmark}/>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={buttonHolderClasses}>
                    <button
                      className="update-button"
                      disabled={!updateConfigPossible}
                      onClick={updateProductConfig}
                    >
                      <FontAwesomeIcon icon={faPenToSquare} className="update-icon" />
                      Update Config
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col style={{ overflowX: "auto" }}>
              <h2>System Sound Power</h2>
              <table className="sytem-sound-power-table">
                <tr>
                  <th className="center">
                    <div className="sytem-sound-power-table-header center">
                      Type
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      63 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      125 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      250 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      500 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      1,000 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      2,000 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      4,000 Hz
                    </div>
                  </th>
                  <th>
                    <div className="sytem-sound-power-table-header">
                      8,000 Hz
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <div className="sytem-sound-power-table-header type">
                      Radiated
                    </div>
                  </th>
                  <td>
                    <div className="system-sound-power-table-data">
                      {configuration?.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz125.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz250.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz500.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz1000.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz2000.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz4000.radiated.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz8000.radiated.toFixed(0))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div className="sytem-sound-power-table-header center">
                      Unit Discharge
                    </div>
                  </th>
                  <td>
                    <div className="system-sound-power-table-data">
                      {configuration?.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz125.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz250.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz500.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz1000.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz2000.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz4000.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz8000.unitDischarge.toFixed(0))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div className="sytem-sound-power-table-header center">
                      Unit Return
                    </div>
                  </th>
                  <td>
                    <div className="system-sound-power-table-data">
                      {configuration?.model === "N88-63314" ? "-" : soundPrettyOutput(soundRPM?.hz63.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz125.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz250.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz500.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz1000.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz2000.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz4000.unitReturn.toFixed(0))}
                    </div>
                  </td>
                  <td>
                    <div className="system-sound-power-table-data">
                      {soundPrettyOutput(soundRPM?.hz8000.unitReturn.toFixed(0))}
                    </div>
                  </td>
                </tr>
              </table>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {modal
        ? (
          <Modal isOpen={!!configuration} style={{ width: '70vw', minWidth: '1000px' }}>
            {container}
          </Modal>
        )
        : (
          <Col
            hidden={!configuration}
            className="expanded-configuration-details-container"
          >
            {container}
          </Col>
        )}
    </>
  );
};

// const StatsHeader = ({ label }: { label: string }) => (
//   <div className='stats-header'>
//     <span>
//       {label}
//     </span>
//   </div>
// );

const StatRow = ({
  label,
  value,
  symbol,
}: { label: string, value: any, symbol: string }) => (
  <div className="stat-row-container">
    <div className="state-row-label">
      <span>{label}</span>
    </div>
    <div>
      <span>{value} {symbol}</span>
    </div>
  </div>
);

export default ExpandedConfigurationDetails;